/*----------  variables
------------------------------------------------------------------------------*/

// Fonts
$font-hema: "FuturaHEMAProOT", "Century Gothic", CenturyGothic, AppleGothic, sans-serif;

// Colors
$white: #fff;
$black: #000;

$grey-darker: #323232;
$grey-dark: #4a4a4a;
$greyish: #c4c4c4;
$grey: #8b8a87;
$grey-light: #9b9b9b;
$alto: #c6c6c6;
$silver: #cccccc;
$gainsboro: #d8d8d8;
$grey-lighter: #e5e3de;
$whitesmoke: #f1efeb;
$whitish: #EEEEEE;
$borderColor: #E2E2E2;
$borderColorTwo: #BFBFBF;

$gold-light: #f3c672;
$gold: #f8b840;
$blue: #5dc5e3;
$blue-light: #bae8f5;
$blue-dark: #4979c3;
$cyan: #3eafcf;
$red: #da2d2a;
$green: #9ac122;
$green-dark: #779514;
$orange: #dc6a21;
$pink: #f265b1;
$pink-light: #fdc7cc;
$magenta: #d8358d;
$violet: #8f1982;

