/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 50px;
}


/*
 * Global add-ons
 */

.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.table > tbody > tr > td {
     vertical-align: middle;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
  border: 0;
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
  display: none;
}
@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
  }
}

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px; /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}
.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #428bca;
}


/*
 * Main content
 */

.main {
  padding: 20px;
}
@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.main .page-header {
  margin-top: 0;
}


/*
 * Placeholder dashboard ideas
 */

.placeholders {
  margin-bottom: 30px;
  text-align: center;
}
.placeholders h4 {
  margin-bottom: 0;
}
.placeholder {
  margin-bottom: 20px;
}
.placeholder img {
  display: inline-block;
}

/* images */
img.thumb{max-width: 44px; max-height: 44px; display: inline-block;border: solid 1px #eee;}
img.thumb-2{max-width: 66px !important; max-height: 44px; display: inline-block;}
span.thumb-notfound{font-size: 40px; color: #999;}
img.thumb-hover{cursor: zoom-in;}

span.sm-glyph{font-size: 10px;}

.form-control-checkbox-wrap {
    padding-top: 8px;
}

.left {
    float: left;
}

.hide {
  display: none;
}

// Override Bootstrap because of DatePicker
.btn-group .btn.btn-default.active[data-no-z-index=true] {
  z-index: 0;
}


// Custom
.text-right {
  text-align: right;
}

.font-normal {
  font-weight: normal;
}

.flex-row {
  position: relative;
  display: flex;
  flex-direction: row;
}

.flex-right {
  position: relative;
  display: flex;
  justify-content: end;
}

// Font Page
.row.font-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
  .col-sm-6 {
    padding: 0;
    margin: 0;
    label {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: normal;

      select {
        margin: 0px 5px;
        width: 75px;
      }

      input {
        width: 200px;
        margin-left: 5px;
      }
    }

    &.right {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: end;
      padding-right: 0;
      margin-right: 0;
    }
  }
}

.paging {
  .active {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
  }

  .not-allowed {
    cursor: not-allowed;
  }
}

.w100 {
  width: 100%;
}

#sortable {
  th {
    user-select: all;
  }
  td {
    user-select: all;
  }
}

.ui-state-default {
  background-color: #f6f6f6;
}