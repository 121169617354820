/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 50px tall */
body {
    padding-top: 50px;
  }
  
  
  /*
   * Global add-ons
   */
  
  .sub-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .table > tbody > tr > td {
       vertical-align: middle;
  }
  
  /*
   * Top navigation
   * Hide default border to remove 1px line.
   */
  .navbar-fixed-top {
    border: 0;
  }
  
  /*
   * Sidebar
   */
  
  /* Hide for mobile, show later */
  .sidebar {
    display: none;
  }
  @media (min-width: 768px) {
    .sidebar {
      position: fixed;
      top: 51px;
      bottom: 0;
      left: 0;
      z-index: 1000;
      display: block;
      padding: 20px;
      overflow-x: hidden;
      overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
      background-color: #f5f5f5;
      border-right: 1px solid #eee;
    }
  }
  
  /* Sidebar navigation */
  .nav-sidebar {
    margin-right: -21px; /* 20px padding + 1px border */
    margin-bottom: 20px;
    margin-left: -20px;
  }
  .nav-sidebar > li > a {
    padding-right: 20px;
    padding-left: 20px;
  }
  .nav-sidebar > .active > a,
  .nav-sidebar > .active > a:hover,
  .nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: #428bca;
  }
  
  
  /*
   * Main content
   */
  
  .main {
    padding: 20px;
  }
  @media (min-width: 768px) {
    .main {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  .main .page-header {
    margin-top: 0;
  }
  
  
  /*
   * Placeholder dashboard ideas
   */
  
  .placeholders {
    margin-bottom: 30px;
    text-align: center;
  }
  .placeholders h4 {
    margin-bottom: 0;
  }
  .placeholder {
    margin-bottom: 20px;
  }
  .placeholder img {
    display: inline-block;
  }
  
  /* images */
  img.thumb{max-width: 44px; max-height: 44px; display: inline-block;border: solid 1px #eee;}
  img.thumb-2{max-width: 66px !important; max-height: 44px; display: inline-block;}
  span.thumb-notfound{font-size: 40px; color: #999;}
  img.thumb-hover{cursor: zoom-in;}
  
  span.sm-glyph{font-size: 10px;}
  
  .form-control-checkbox-wrap {
      padding-top: 8px;
  }
  
  .left {
      float: left;
  }
  .ui-sortable:after {
    opacity: 0.2;
    content: "\e150";
    font-family: 'Glyphicons Halflings';
    cursor: pointer;
  }

  .ui-sortable[sort=asc]:after {
    font-family: 'Glyphicons Halflings';
    content: "\e155";
  }

  .ui-sortable[sort=desc]:after {
    font-family: 'Glyphicons Halflings';
    content: "\e156";
  }

  .ui-reorder {
    cursor: grabbing;
  }

  .ui-reorder[draggable=true] {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
  }

  .ui-reorder[draggable=true]:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing;
  }
  
  .ui-reorder-disabled {
    cursor: no-drop;
  }

  .code-snippet {
    border: 0;
    background-color: white;
  }

  .table.dataTable td.dataTables_empty {
    text-align: center;
  }

  .ui-state-default {
    background-color: #f6f6f6;
  }

  .pointer {
    cursor: pointer;
  }