@import '../config/variables';

.container {
  background: $white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;

    .errorCode {
      font-size: 25rem;
      font-weight: bold;
      color: $grey-lighter;
      text-align: center;
      user-select: none;
    }

    .errorMessage {
      display: flex;
      justify-content: center;
      
      div, span {
        font-size: 2rem;
        font-weight: bolder;
        text-align: center;
        color: $grey-darker;
        text-align: center;

        &.page {
          color: $grey-light;
        }
      }
    }
  }
}

.backBottom {
  width: min-content;
	position: relative;
  height: 100px;
  display: flex;
  justify-content: center;
  width: 100%;

  .wrapper {
    position: absolute;
		width: fit-content;
    padding: 30px 0;

    button {
      background: $grey-lighter;
      border-radius: 6px;
      padding: 15px 40px;
			cursor: pointer;

      &:hover {
        background-color: $grey-lighter;
        border: 3px solid $grey-lighter;
        box-shadow: 0 0.5em 0.5em -0.4em $black;
        transform: translateY(-0.25em);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .container {
    .wrapper {
      .errorCode {
        font-size: 15rem;
      }
      .errorMessage {
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    .wrapper {
      .errorCode {
        font-size: 12rem;
      }
      .errorMessage {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .container {
    .wrapper {
      .errorCode {
        font-size: 8rem;
      }
      .errorMessage {
        font-size: 0.8rem;
      }
    }
  }
}
